
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Form, Field } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import GoBackButton from "@/components/reusable/GoBackButton.vue";
import TotalPremiumWidget from "@/components/packages/TotalPremiumWidget.vue";
import { useRouter } from "vue-router";
import * as Yup from "yup";
import { Mutations } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import MotorInsuranceAdditionForm from "@/components/reusable/MotorInsuranceAdditionForm.vue";

export default defineComponent({
  name: "motor-insurance-addition-form",
  components: {
    Form,
    Field,
    GoBackButton,
    TotalPremiumWidget,
    MotorInsuranceAdditionForm,
  },
  data() {
    return {
      mainDisplay: false,
      displayPMI: false,
      displayCMI: false,
      displayForm: false,
      displayError: false,
      policies: [],
      covers: [],
      policySummary: {
        client: "",
        type: "",
        leadInsurer: "",
        number: "",
        year: "",
        startDate: "",
        expiryDate: "",
        reference: "",
      },
      policyDetails: [],
      totalPremium: 0.0 as number,
      insurancePackage: "",
      motorPolicyId: "",
      policy: "" as string,
      publicId: this.$route.params.publicId as string,
      premiumCalculationRoute: "" as string,
      result: {
        privateCartItemPublicId: "",
        commercialCartItemPublicId: "",
        hasPrivateCartItem: true || (false as boolean),
        hasCommercialCartItem: true || (false as boolean),
      },
    };
  },
  setup() {
    const submitButton1 = ref<HTMLElement | null>(null);
    const router = useRouter();
    const store = useStore();
    const userType = store.getters.currentUser;

    const metadataValidator = Yup.object().shape({
      metadata: Yup.array()
        .of(
          Yup.object().shape({
            vehicleOwner: Yup.string()
              .required()
              // .min(6)
              .label("Vehicle owner name"),
            vehicleValue: Yup.string()
              .when("package", {
                is: (value) => value != JwtService.getThirdPartyPM(),
                then: Yup.string()
                  // .min(6)
                  .required(),
              })
              .label("Vehicle value"),
            package: Yup.mixed()
              .required()
              .label("Cover"),
            vehicleMake: Yup.mixed()
              .required()
              .label("Vehicle brand"),
            vehicleModel: Yup.string()
              .required()
              .label("Vehicle model"),
            vehicleRegistrationNumber: Yup.string()
              .required()
              // .min(8)
              .label("Vehicle registration number"),
            chassisNumber: Yup.string()
              .required()
              .min(1)
              .label("Chassis number"),
            engineNumber: Yup.string()
              .required()
              .min(1)
              .label("Engine number"),
            bodyType: Yup.string()
              .required()
              .label("Body type"),
            vehicleColor: Yup.string()
              .required()
              .label("Vehicle colour"),
            vehicleLicense: Yup.mixed()
              .required()
              .label("Vehicle license"),
          })
        )
        .strict(),
    });

    const saveAddition = (values, { resetForm }) => {
      if (submitButton1.value) {
        // Activate indicator
        submitButton1.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          submitButton1.value?.removeAttribute("data-kt-indicator");

          // Send form data
          if (JwtService.getToken()) {
            const formData = new FormData();

            for (let x = 0; x < values["metadata"].length; x++) {
              for (const [key, value] of Object.entries(
                values["metadata"][x]
              )) {
                if (Array.isArray(value)) {
                  value.forEach((itemValue, index) => {
                    formData.append(
                      `vehicleDetails[${key}][${x}][${index}]`,
                      itemValue as string | Blob
                    );
                  });
                } else {
                  formData.append(
                    `vehicleDetails[${key}][${x}]`,
                    value as string | Blob
                  );
                }
              }
            }

            formData.append("totalPremium", values["totalPremium"]);
            formData.append("totalSumInsured", values["totalSumInsured"]);
            formData.append("businessType", values["businessType"]);
            formData.append("businessClass", values["businessClass"]);
            formData.append("underwriter", values["underwriter"]);
            // return new Response(formData).text().then(console.log);

            ApiService.setHeader();
            ApiService.post(
              variables.CART_ADDITION_ROUTE + values["motorPolicyId"],
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )
              .then((response) => {
                store.commit(Mutations.CART_MUTATION, response.data.data.cart);
                resetForm();
                variables.toastAlert(response.data.data.message, "success");
                router.push({
                  name: "insurance-packages-cart-debit-note",
                  params: {
                    publicId: response.data.data.cartItemPublicId,
                  },
                });
                // router.push({
                //   name: "insurance-packages-policy-period",
                //   params: {
                //     publicId: response.data.data.cartItemPublicId,
                //   },
                // });
              })
              .catch(function(error) {
                variables.toastAlert(error.response.data.error, "error");
              });
          }
        }, 2000);
      }
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("Addition", [
        "Insurance Packages",
        "Motor Insurance Policy",
      ]);

      MenuComponent.reinitialization();
    });

    return {
      submitButton1,
      metadataValidator,
      userType,
      saveAddition,
    };
  },
  created() {
    this.hasExistingCartPackages();

    // Get vehicle policies only
    this.getPolicies();

    //Set page title
    document.title = "Addition: Motor Insurance | " + process.env.VUE_APP_TITLE;
  },
  methods: {
    hasExistingCartPackages() {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(`${variables.CHECK_CART_PACKAGE_ROUTE}/MOT/addition`)
          .then(({ data }) => {
            //Assign data to form fields
            this.result = data.data;
            this.displayCMI = data.data.hasCommercialCartItem;
            this.displayPMI = data.data.hasPrivateCartItem;

            if (this.displayCMI || this.displayPMI) {
              this.mainDisplay = true;
            }
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    getPolicies() {
      //Fetch client policies
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(`${variables.POLICIES_ROUTE}/business-class/MOT`)
          .then(({ data }) => {
            //Assign data to props
            if (data.data != "") {
              this.displayError = false;
              this.policies = data.data;

              if (this.publicId !== undefined && this.publicId !== null) {
                this.policy = this.publicId;
                this.getPolicyInformation(this.publicId);
              }
            } else {
              this.displayError = true;
            }
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    getPolicyInformation(value) {
      this.premiumCalculationRoute = `${variables.CART_ROUTE}/premium/addition/${value}`;
      this.motorPolicyId = value;
      //Fetch individual vehicle policy information
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(`${variables.POLICIES_ROUTE}/${value}`)
          .then(({ data }) => {
            //Assign data to variables
            this.policySummary = data?.data?.policySummary;
            this.policyDetails = data?.data?.items;
            this.totalPremium = data?.data?.policySummary?.premium;

            if (data.data != "") {
              this.displayForm = true;
              this.covers = data?.data?.policySummary?.packages;
              this.insurancePackage =
                data?.data?.policySummary?.businessTypeShortName;
            }
          })
          .catch(function(error) {
            console.log(error);
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
    formatDate(dateString) {
      return variables.formatDate(dateString);
    },
  },
});
